<template>
  <div class="rn_my_box_13">
    <div class="sect_sort">
      <div>
        <select name="" v-model="range" @change="changeSort">
          <option value="month">이번 달 보기</option>
          <option value="year">올해 보기</option>
          <option value="all">전체 보기</option>
        </select>
      </div>
      <div>
        <select name="" v-model="sort" @change="changeSort">
          <option value="latest">최신순</option>
          <option value="click">클릭수순</option>
          <option value="oclick">원문읽기수순</option>
        </select>
      </div>
    </div>
    <div class="sect_info">
      <div class="box">
        <div class="left">{{ totalCntOwnTitle }}</div>
        <div class="right">총 {{ won(totalCntOwn) }}</div>
      </div>
      <div class="box">
        <div class="left">{{ totalCntTwoTitle }}</div>
        <div class="right">총 {{ won(totalCntTwo) }}</div>
      </div>
    </div>
    <div class="sect_table">
      <table summary="">
        <colgroup>
          <col width="*" />
          <col width="65px" />
          <col width="85px" />
        </colgroup>
        <thead>
          <tr>
            <th>리뷰 제목</th>
            <th>클릭수</th>
            <th>원문읽기 수</th>
          </tr>
        </thead>
        <tbody v-if="listArray.length > 0">
          <tr v-for="list in listArray" :key="list.seq">
            <td>
              <router-link
                :to="`/review/news_main/${list.option_category1}?category=${list.option_category2}&seq=${list.review_seq}`"
              >
                {{ list.title }}
              </router-link>
            </td>
            <td>{{ list.click }}</td>
            <td>{{ list.news }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <td colspan="3" class="empty">작성한 리뷰가 없습니다.</td>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listArray: {
      type: Array,
      default: () => [],
      required: true,
    },
    totalCntOwn: {
      type: Number,
      default: 0,
      required: true,
    },
    totalCntTwo: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      range: "month",
      sort: "latest",
      totalCntOwnTitle: "이번 달 리뷰 클릭수",
      totalCntTwoTitle: "이번 달 원문읽기 건수",
    };
  },
  methods: {
    changeSort() {
      switch (this.range) {
        case "month":
          this.totalCntOwnTitle = "이번 달 리뷰 클릭수";
          this.totalCntTwoTitle = "이번 달 원문읽기 건수";
          break;
        case "year":
          this.totalCntOwnTitle = "올해의 리뷰 클릭수";
          this.totalCntTwoTitle = "올해의 원문읽기 건수";
          break;
        case "all":
          this.totalCntOwnTitle = "전체 리뷰 클릭수";
          this.totalCntTwoTitle = "전체 원문읽기 건수";
          break;
        default:
          this.totalCntOwnTitle = "이번 달 리뷰 클릭수";
          this.totalCntTwoTitle = "이번 달 원문읽기 건수";
          break;
      }
      this.$emit("changSort", this.range, this.sort);
    },
    won(str) {
      return this.$won(str);
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  text-align: center;
  padding: 12px 0;
}
</style>
