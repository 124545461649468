<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      실시간 리뷰 현황<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <!-- <div :class="menu <= 1 ? 'rn_my_empty_20' : 'rn_my_empty_30'"></div> -->
    <!---->
    <ReviewMainMenu
      v-if="reviewMenuList"
      :isMoreMenu="isMoreMenu"
      :reviewMenuList="reviewMenuList"
      @getMenu="getMenu"
    />
    <div class="info rn_my_empty_20" v-if="menu === 1">
      · 실시간 리뷰 현황은 구매건수 1 이상만 노출됩니다. 노출 기준일은
      구매확정일입니다.(상품)
    </div>
    <!---->
    <div class="rn_my_empty_20" v-else></div>
    <!---->
    <ReviewGoods
      v-if="menu === 1"
      @changSort="changSort"
      :listArray="listArray"
      :totalCntOwn="parseInt(totalCntOwn)"
      :totalCntTwo="parseInt(totalCntTwo)"
    />
    <ReviewNews
      v-if="menu === 3"
      @changSort="changSort"
      :listArray="listArray"
      :totalCntOwn="parseInt(totalCntOwn)"
      :totalCntTwo="parseInt(totalCntTwo)"
    />
    <div class="rn_more" v-if="totalPage > page">
      <router-link to="" @click.native="getReviewList()"
        >{{ limit }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";

import ReviewMainMenu from "@/components/review/state/ReviewMainMenu";
import ReviewGoods from "@/components/review/state/ReviewGoods";
import ReviewNews from "@/components/review/state/ReviewNews";
export default {
  data() {
    return {
      defaultCnt: 5, //이상이면 swiper library 적용
      isMoreMenu: false,
      menu: 0,
      page: 0,
      sort: "latest",
      range: "month",
    };
  },
  created() {
    //초기화;
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getReviewMenu();
  },
  methods: {
    async getReviewMenu() {
      await this.$store.dispatch("review/getReviewMenu");

      // 작성한 리뷰가 하나도 없다면 뒤로
      // if (this.reviewMenuList.length <= 0) {
      //   this.$toast.default("작성한 리뷰가 없습니다.");
      //   this.$router.push("/mypage/my_info");
      //   return false;
      // }
      this.isMoreMenu = parseInt(this.reviewMenuList.length) > this.defaultCnt;
      this.menu = parseInt(this.reviewMenuList[0].seq);
      this.getReviewList(true);
    },
    getMenu(num) {
      this.$store.dispatch("review/reviewStateReset");
      this.menu = num;
      this.sort = "latest";
      this.range = "month";
      this.getReviewList(true);
    },
    async getReviewList(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("review/getReviewList", {
        seq: this.menu,
        page: this.page,
        range: this.range,
        sort: this.sort,
        reset,
      });
      this.page++;
    },
    changSort(range, sort) {
      this.range = range;
      this.sort = sort;
      this.getReviewList(true);
    },
  },
  computed: {
    ...mapState("mypage", ["myInfo"]),
    ...mapState("review", [
      "reviewMenuList",
      "result",
      "msg",
      "listArray",
      "totalPage",
      "totalCntOwn",
      "totalCntTwo",
      "limit",
    ]),
  },
  components: { ReviewGoods, ReviewNews, ReviewMainMenu },
};
</script>

<style lang="scss" scoped>
.info {
  font-size: 12px;
  padding: 10px 5px 20px 5px;
  line-height: 16px;
}
</style>
